import { keyframes } from "@emotion/core";

export const BREAKPOINTS = {
  tiny: 420,
  small: 768,
  medium: 960,
  large: 1440,
};

export const BREAKPOINT_SPACING = {
  tiny: 50,
  small: 100,
  medium: 140,
  large: 180,
};

export const COLORS = {
  darkest: `#000000`,
  darker: `#091124`,
  dark: `#001E60`,
  mid: `#556483`,
  light: `#E9F7FE`,
  lightest: `#FFFFFF`,

  primary: `#20A1DB`,
  primaryDark: `#001137`,
  secondary: `#01CE77`,

  // WHY: The brand colours aren’t accessible on #FFF for text so `primaryA11y` and `secondaryA11y`
  // should be used for small text, and `primaryA11yLarge` for large text (it is very subtly darker,
  // but not different to the naked eye).
  primaryA11y: `#005AA1`,
  primaryA11yLarge: `#1F9CD6`,
  secondaryA11y: `#018347`,
  secondaryA11yDark: `#004e2a`,
  secondaryA11yDarker: `#002d18`,

  tertiary: `#FF4E31`,
  tertiaryA11y: `#DD361B`,

  flourish1: `#FEB660`,
  flourish2: `#009ADE`,
  flourish3: `#FA5B4B`
};

export const TRANSITION_DURATIONS = {
  regular: `250ms`,
};

export const TRANSITION_EASINGS = {
  regular: `ease-in-out`,
};

export const TRANSITIONS = {
  regular: `${TRANSITION_DURATIONS.regular} ${TRANSITION_EASINGS.regular}`,
};

export const blinkAnimation = keyframes`
  0%, 100% { opacity: 0.5}
  50% { opacity: 1 }
`;

export const FONT_FAMILIES = {
  heading: {
    servicelink: {
      family: `sole-headline, serif`,
      fontSizeModifier: 1,
      lineHeight: 1.15,
      weight: 500,
    },
    loancare: {
      family: `sole-headline, serif`,
      fontSizeModifier: 1,
      lineHeight: 1.15,
      weight: 500,
    },
    exos: {
      family: `circular, sans-serif`,
      fontSizeModifier: 0.95,
      lineHeight: 1.15,
      weight: 600,
    },
  },
  body: `Roboto, sans-serif`,
};

export const DEFAULT_FONT_SIZES_MAP = {
  tiny: [10, 12],
  small: [12, 16],
  regular: [14, 18],
  medium: [16, 20],
  large: [18, 22],
};

export const BRANDS = {
  servicelink: `servicelink`,
  exos: `exos`,
  loancare: `loancare`,
};

export const HEADER_PADDING = {
  tiny: 8,
  small: 16,
  medium: 20,
  large: 26,
};

export const HEADER_HEIGHT = {
  tiny: 48,
  small: 72,
  medium: 80,
  large: 96,
};
